import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Loader, Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { OrderItemFragment, useGetOrderProductQuery } from '@hooks/api/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${ResponsivePXValue('height', { mobile: 'fit-content', tablet: '190px', desktop: '190px' })}
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
`

const CardLink = styled.a`
  width: 100%;
  height: 100%;
  flex-direction: column;
`
const ImageContainer = styled.div`
  flex-basis: 100%;
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  ${ResponsivePXValue('height', { mobile: '120px', tablet: '146px', desktop: '146px' })}
  ${ResponsivePXValue('padding', { mobile: '9px 0 3px 0', tablet: '9px 0', desktop: '18px 0' })}

`

const NotAvailable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '120px', tablet: '146px', desktop: '146px' })}
  flex-direction: column;
  ${ResponsivePXValue('padding', '8px')}
  ${ResponsivePXValue('gap', '8px')}
`

export interface OrderProductCardProps {
  product: OrderItemFragment
  disabled?: boolean
}

export function OrderProductCard({ product }: OrderProductCardProps): JSX.Element {

  const { data, loading: productLoading } = useGetOrderProductQuery({ variables: { sku: product.productSku } })
  const navigate = useNavigate()

  const openDetails = (): void =>
    navigate(`/${product.productUrlKey}`)

  const _handleLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    e.preventDefault()
    openDetails()
  }

  return (
    <Container>
      <Choose>
        <When condition={productLoading}>
          <Loader />
        </When>
        <When condition={!productLoading && !data?.product}>
          <NotAvailable>
            <Paragraph bold align='center'>{product.productName}</Paragraph>
            <Paragraph align='center'>NO LONGER AVAILABLE</Paragraph>
          </NotAvailable>
        </When>
        <Otherwise>
          <CardLink href={`/${product?.productUrlKey}`} onClick={_handleLink}>
            <ImageContainer>
              <ResponsiveImage image={data?.product?.coverImage} objectFit='contain' />
            </ImageContainer>
          </CardLink>
        </Otherwise>
      </Choose>
    </Container>
  )

}
